import React from "react";
import ContactForm from "../components/ContactForm/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="page contact landing-fragment" id="contact">
      <Helmet>
        <title>
          Contactez Artcys Consulting - Services de Consulting en Ligne
        </title>
        <meta
          name="keywords"
          content="contact Artcys Consulting, consulting en ligne, service de conseil, nous contacter, demande de devis"
        />
        <meta
          name="description"
          content="Contactez Artcys Consulting pour des services de consulting en ligne, y compris SEO, Social Media Marketing, Création de contenu, Social Media Management et Création Web clé en main. Demandez un devis ou posez vos questions aujourd'hui."
        />
      </Helmet>
      <ContactForm />
    </div>
  );
};

export default Contact;
