import React from "react";
import "./style.css";
import { InlineWidget } from "react-calendly";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Cette bibliothèque peut vous aider à détecter quand un élément est dans la vue
import pdp from "../../../assets/DIMITRI.webp";
const Calender = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `-${15}% 0px`, // Définir la marge d'intersection
  });

  return (
    <div className="landing-fragment sections calender" id="calender">
      <div className="row section4">
        <motion.div
          className="calendercontainer"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <InlineWidget
            styles={{
              height: "800px",
              width: "100%",

              borderRadius: "20px",
            }}
            pageSettings={{
              backgroundColor: "EDF1F5",
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: "2e517e",
              textColor: "000000",
            }}
            url="https://calendly.com/arctys-consulting/dimitri_yeremian?hide_event_type_details=1&hide_gdpr_banner=1"
          />
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 100 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <img src={pdp} className="pdp" alt="Dimitri Yeremian" />
          <h2>PLANIFIEZ VOTRE APPEL AVEC DIMITRI</h2>
          <h1>Faites-nous part de vos objectifs de développement </h1>
          <p>
            À la fin de ce meeting, nous aurons établi vos objectifs en matière
            d’expansion digitale. Vous aurez ainsi une vision claire des
            différentes étapes et leviers nécéssaires à votre croissance
            numérique.
          </p>

          <h2>CET APPEL EST FAIT POUR VOUS SI : </h2>

          <ul>
            <li>
              <ion-icon name="checkmark-circle"></ion-icon>
              Vous souhaitez démarrer votre expansion digitale sans savoir par
              où commencer.
            </li>
            <li>
              <ion-icon name="checkmark-circle"></ion-icon>
              Vous cherchez une Agence qui fera de l’image de votre entreprise
              sa priorité
            </li>
            <li>
              <ion-icon name="checkmark-circle"></ion-icon>
              Vous souhaitez confier la conception, l’excécution et l’analyse de
              votre stratégie de marketing digital à nos experts.
            </li>
          </ul>
        </motion.div>
      </div>
    </div>
  );
};

export default Calender;
