import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Navbar from "./components/NavBar/Navbar";

import { ToastContainer } from "react-toastify";
import Footer from "./components/Footer/Footer";
import { motion } from "framer-motion";

function App() {
  return (
    <div className="App">
      <ToastContainer progressClassName="toastProgress" />

      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            // Utiliser motion.div comme conteneur d'animation pour chaque route
            <motion.div
              key="/"
              {...{
                initial: { opacity: 0, y: 100 },
                animate: { opacity: 1, y: 0 },
                exit: { opacity: 0, y: -100 },
              }}
              transition={{ duration: 0.6 }}
            >
              <Home />
            </motion.div>
          }
        />
        <Route
          path="/contact"
          element={
            // Utiliser motion.div comme conteneur d'animation pour chaque route
            <motion.div
              key="/contact"
              {...{
                initial: { opacity: 0, y: 100 },
                animate: { opacity: 1, y: 0 },
                exit: { opacity: 0, y: -100 },
              }}
              transition={{ duration: 0.6 }}
            >
              <Contact />
            </motion.div>
          }
        />
        {/*  <Route status={404} element={<NotFound></NotFound>}></Route>
        <Route path="*" element={<NotFound></NotFound>}></Route> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
