import React from "react";
import "./contact.css";
import { useState } from "react";
import { send } from "emailjs-com";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const init = {
  from_email: "",
  from_name: "",
  message: "",
  subject: "",
};
const emailRegex =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const ContactForm = () => {
  const [toSend, setToSend] = useState(init);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 6000,
    transition: Slide,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  function isEmailValid(email) {
    // Expression régulière pour valider une adresse e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Testez l'adresse e-mail avec la regex
    return emailPattern.test(email);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (!localStorage.getItem("email")) {
      if (toSend.message.length < 10) {
        toast.error("Message trop court.", toastOptions);
      } else if (toSend.from_name.length < 2) {
        toast.error("Nom trop court.", toastOptions);
      } else if (!isEmailValid(toSend.from_email)) {
        toast.error("Adresse mail invalide.", toastOptions);
      } else if (toSend.subject.length < 4) {
        toast.error("Sujet trop court.", toastOptions);
      } else {
        send("service_hb8d0qk", "template_qv3levk", toSend, "_nUh4cmQ8n0Xk7PfZ")
          .then((response) => {
            toast.success("The email has been sent !", toastOptions);
            localStorage.setItem("email", true);
          })
          .catch((err) => {
            toast.error(
              "Une erreur s'est produite lors de l'envoi du mail.",
              toastOptions
            );
          });
        setToSend(init);
      }
    } else {
      toast.error("Vous avez déjà envoyé un email.", toastOptions);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="fields">
        <div className="left-border"></div>

        <div className="content">
          <div>
            <h1>Formulaire de contact</h1>
            <p>
              Vous avez une question ou souhaitez travailler avec nous ?
              N'hésitez pas à nous contacter en utilisant le formulaire
              ci-dessous.
            </p>
          </div>

          <div className="field">
            <label htmlFor="">Votre nom</label>
            <input
              type="text"
              onChange={handleChange}
              name="from_name"
              value={toSend.from_name}
            />
          </div>

          <div className="field">
            <label htmlFor="">Votre adresse mail</label>
            <input
              type="text"
              onChange={handleChange}
              name="from_email"
              value={toSend.from_email}
            />
          </div>

          <div className="field">
            <label htmlFor="">Objet de votre message</label>
            <input
              type="text"
              onChange={handleChange}
              name="subject"
              value={toSend.subject}
            />
          </div>

          <div className="field">
            <label htmlFor="">Contenu du message</label>
            <textarea
              type="text"
              onChange={handleChange}
              name="message"
              value={toSend.message}
            />
          </div>
        </div>
      </div>

      <button className="callto">ENVOYER</button>
    </form>
  );
};

export default ContactForm;
