import React from "react";
import logo from "../assets/logo.webp";
import CallTo from "../components/CallTo/CallTo";
import Section2 from "../components/HomeComponents/Section2/Section2";
import Section3 from "../components/HomeComponents/Section3/Section3";
import { Helmet } from "react-helmet";
import Calender from "../components/HomeComponents/Calender/Calender";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Cette bibliothèque peut vous aider à détecter quand un élément est dans la vue
import { useState, useEffect } from "react";
const Home = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `${50}% 0px`, // Définir la marge d'intersection
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  // Utilisez useAnimation pour contrôler l'animation de Framer Motion
  const animationControls = useAnimation();

  useEffect(() => {
    if (!hasAnimated && inView) {
      // Définissez l'animation lorsque l'élément est dans la vue
      animationControls.start({
        rotateY: 180, // Rotation 3D sur l'axe Y
        opacity: 1,
        transition: { duration: 1, ease: "easeOut" },
      });
      setHasAnimated(true); // Marquez que l'animation a été déclenchée
    }
  }, [hasAnimated, inView, animationControls]);

  return (
    <div className="page home" id="home">
      <Helmet>
        <title>Artcys Consulting - Accueil</title>
        <meta
          name="keywords"
          content="consulting en ligne, SEO, Social Media Marketing, Création de contenu, Social Media Management, Création Web clé en main"
        />
        <meta
          name="description"
          content="Artcys Consulting vous offre des services complets pour améliorer votre présence en ligne. Notre expertise en SEO, Social Media Marketing, Création de contenu, Social Media Management et Création Web clé en main vous permet de réussir dans le monde numérique."
        />
      </Helmet>

      <div className="landing-fragment row">
        <div>
          <motion.h1
            className="very"
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -200 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            ALCHIMISTES DU NUMERIQUE
          </motion.h1>
          <div className="bg1">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -200 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
            >
              <h1>
                Réinventons votre empreinte digitale optimisée par nos
                stratégies en marketing numérique
              </h1>
            </motion.div>
          </div>

          <motion.div
            className="content"
            ref={ref}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
            transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
          >
            <CallTo
              text={"Découvrir Arctys"}
              id={"anchor"}
              offset={"365px"}
              exception={true}
            />
          </motion.div>
        </div>
        <div>
          <motion.img
            src={logo}
            alt="ARCTYS Consulting Logo"
            className="logo"
            animate={animationControls}
            initial={{ opacity: 0 }}
          />{" "}
        </div>
      </div>
      <Section2 />
      <Section3 />
      {/*<Brand />*/}
      <Calender />
    </div>
  );
};

export default Home;
