import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Cette bibliothèque peut vous aider à détecter quand un élément est dans la vue
import { useNavigate } from "react-router-dom";
const Section3 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `-${15}% 0px`, // Définir la marge d'intersection
  });

  const navigate = useNavigate();
  const [active, setActive] = useState(-1);
  const items = [
    {
      name: "SEO",
      question: "Qu'est que le SEO ?",
      answer:
        "Le SEO, ou Search Engine Optimization, est l'ensemble des techniques et stratégies visant à améliorer la visibilité d'un site web dans les résultats des moteurs de recherche tels que Google. C'est important car une meilleure visibilité signifie plus de trafic organique, ce qui peut augmenter la notoriété, la crédibilité et les opportunités commerciales d'un site. Le SEO permet aux utilisateurs de trouver plus facilement votre contenu en ligne, ce qui peut générer plus de visiteurs et de conversions pour votre site web.",
      logo: <ion-icon name="bar-chart-outline"></ion-icon>,
    },
    {
      name: "Social Media Marketing",
      question: "Qu'est que le social media marketing ?",
      answer:
        "Le social media marketing consiste à promouvoir une entreprise via les réseaux sociaux pour augmenter la visibilité, l'engagement et les ventes.",
      logo: <ion-icon name="logo-instagram"></ion-icon>,
    },
    {
      name: "Création de contenu",
      question: "Pourquoi créer du contenu ?",
      answer:
        "La création de contenu consiste à produire des textes, images, vidéos et autres médias destinés à être partagés en ligne. C'est important car cela permet de fournir de l'information, de divertir, d'éduquer ou de promouvoir des produits et services, ce qui attire l'attention des audiences en ligne, renforce la notoriété de la marque, favorise l'engagement des utilisateurs et peut conduire à des conversions et à la fidélisation des clients. En somme, la création de contenu est un élément clé du marketing numérique et de la communication en ligne.",
      logo: <ion-icon name="logo-instagram"></ion-icon>,
    },
    {
      name: "Social Media Management",
      answer:
        "Le Social Media Management désigne la gestion active et stratégique de la présence d'une entreprise ou d'une marque sur les médias sociaux. Il englobe la création, la planification, la publication et la gestion du contenu sur les plateformes de médias sociaux telles que Facebook, Twitter, Instagram, LinkedIn, etc. Le Social Media Management vise à développer une présence en ligne cohérente, à renforcer l'engagement de l'audience et à atteindre les objectifs marketing de l'entreprise.",
      question: "Qu'est que le social media management ?",

      logo: <ion-icon name="share-social-outline"></ion-icon>,
    },
    {
      name: "Création Web de A à Z, clé en main",
      answer:
        "Un site web est un outil essentiel pour établir une présence en ligne, accroître la visibilité, renforcer la crédibilité et faciliter la communication avec les clients, ce qui peut finalement stimuler la croissance de votre entreprise.",
      question: "Pourquoi un site web ?",

      logo: <ion-icon name="globe-outline"></ion-icon>,
    },
  ];
  return (
    <div className="landing-fragment sections fullplace">
      <div className="row reverse section3">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }}
          transition={{ duration: 0.7, ease: "easeOut" }}
        >
          <h1>Vos Objectifs, Nos Leviers Digitaux </h1>
          <div className="items-container">
            {active >= 0 ? (
              <div className="answer">
                <div className="row">
                  <h3 onClick={() => setActive(-1)} className="back">
                    <ion-icon name="arrow-back"></ion-icon> Retour
                  </h3>{" "}
                  <p>{items[active].question}</p>
                </div>

                <p>{items[active].answer}</p>
              </div>
            ) : (
              <>
                {items.map((item, index) => {
                  return (
                    <p onClick={() => setActive(index)} key={index}>
                      {item.logo} {item.name}{" "}
                      <ion-icon name="information-circle-outline"></ion-icon>
                    </p>
                  );
                })}
              </>
            )}
          </div>
          <button className="callto" onClick={() => navigate("/contact")}>
            Nous contacter
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Section3;
