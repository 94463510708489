import React from "react";
import CallTo from "../../CallTo/CallTo";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Cette bibliothèque peut vous aider à détecter quand un élément est dans la vue
import stats from "../../../assets/stats.webp";
const Section2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `-${15}% 0px`, // Définir la marge d'intersection
  });

  return (
    <div className="landing-fragment sections">
      <div ref={ref} className="row reverse section2">
        <motion.img
          className="ilu"
          alt="Statistiques Croissance"
          src={stats}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        ></motion.img>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <h1>Votre Croissance, Notre Expertise</h1>
          <p>
            Chez ARTCYS, nous développons la stratégie d’expansion digitale qui
            garantit votre succès en ligne.
          </p>
          <p>
            Notre équipe est composée d’experts de chaque domaine du monde
            digital. Ils vous offrent les leviers numériques pour atteindre vos
            objectifs de croissance.
          </p>
          <p className="bold" id="anchor">
            Optez pour <span>l’excellence</span> en matière de campagne
            marketing orientée <span>performance</span>. <br /> <br />
            Parlez nous de vos projets en réservant votre{" "}
            <span>appel offert</span>.
          </p>
          <CallTo
            text={"Planifier un rendez vous"}
            id={"calender"}
            offset={"100px"}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Section2;
