import { useState, useEffect } from "react";
import "./Nav.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isScrolledUp, setIsScrolledUp] = useState(false); // Variable pour déterminer si la page est remontée

  const [lastScrollTime, setLastScrollTime] = useState(Date.now());
  const location = useLocation(); // Obtenir l'emplacement courant

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (lastScrollY < currentScrollY && currentScrollY > 200) {
        setIsNavHidden(true);
        setIsScrolledUp(false); // Réinitialiser lorsque vous faites défiler vers le bas
      } else {
        setIsNavHidden(false);
        if (currentScrollY <= 200) {
          setIsScrolledUp(true); // La page est remontée
        }
      }

      lastScrollY = currentScrollY;
      setLastScrollTime(Date.now());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      // Vérifier si une seconde s'est écoulée depuis le dernier défilement
      if (Date.now() - lastScrollTime >= 1000) {
        if (window.scrollY > 200) {
          setIsNavHidden(true);
        }
      }
    }, 100); // Vérifier toutes les 100 millisecondes

    return () => {
      clearInterval(interval);
    };
  }, [lastScrollTime]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <nav
      className={`nav ${isNavHidden ? "nav--hidden" : ""} ${
        !isScrolledUp ? "scrolled-up" : ""
      }`}
      id="nav"
    >
      <h2 onClick={() => navigate("/")}>
        <span>ARC</span>TYS <span className="little">Consulting</span>
      </h2>
      <Link to="/contact">Nous contacter</Link>
    </nav>
  );
};

export default Navbar;
