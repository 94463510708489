import React from "react";
import logo from "../../assets/logo.webp";
import CallTo from "../CallTo/CallTo";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Cette bibliothèque peut vous aider à détecter quand un élément est dans la vue
import "./footer.css";
const Footer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `-${20}% 0px`, // Définir la marge d'intersection
  });

  return (
    <motion.footer
      ref={ref}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div>
        <img src={logo} className="logo" alt="ARCTYS Consulting logo" />
        <CallTo text={"Retourner en haut"} id={"nav"} offset={"0px"} />
      </div>

      <h1>
        <span>ARC</span>TYS <span className="little">Consulting</span>
      </h1>
      {/* 
         <h3>IAG Online Services - Company Number: 11178977</h3>
      <h4>All Rights Reserved | All Wrongs Reversed</h4>
      <h4>Terms & Conditions | Privacy Policy | Disclaimer</h4>
        */}
    </motion.footer>
  );
};

export default Footer;
