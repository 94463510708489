import React, { useEffect, useState } from "react";
import SmoothScroll from "smooth-scroll";

import "./callTo.css";

const CallTo = ({ text, id, offset, exception }) => {
  const navigateTo = () => {
    const scroll = new SmoothScroll();
    const element = document.getElementById(id);
    if (element) {
      scroll.animateScroll(element, null, { offset: offset });
    }
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (exception) {
      setTimeout(() => {
        setDisabled(false);
      }, 1300);
    } else {
      setDisabled(false);
    }
  });
  return (
    <button className="callto" onClick={() => navigateTo()} disabled={disabled}>
      {text}
    </button>
  );
};

export default CallTo;
